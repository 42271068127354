import platformCredentialsState from './platformCredentialsState';
import marketingData from '../marketing/marketingState';
// import { IPlatformCredentials } from '@minmarketing/shared'

export default {
    user: {
        uuid: "",
        email: "",
        profile: {
            image: {
                path: null,
                url: null,
            },
            logo: {
                name: null,
                url: null,
                path: null,
            }
        },
        testfield: "test",
        companyName: "",
        business_partners: [],
        subscriptionPlan: {
            cancel_at: null,
            cancel_at_period_end: null,
            name: "Loading...",
            plan: {
                visualName: "Loading...",
                name: "Loading...",
                price: "Loading...",
                planColor: "Loading...",
                benfit1: "Loading...",
                benfit2: "Loading...",
                benfit3: "Loading...",
                benfit4: "Loading...",
                benfit5: "Loading...",
                benfit6: "Loading...",
                benfit7: "Loading...",
            },
            priceId: null,
            status: null,
            stripeCustomerId: null,
            SubscriptionId: null,
        },
        platformCredentials: platformCredentialsState, // Integrate the separated state
        featureToggles: {
            checklist: undefined,
        },
        ...marketingData,
    }
}
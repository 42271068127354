// Define interfaces for the state and related entities
interface File {
    fileType: string;
    fullName: string;
    name?: string;
    icon?: string;
    fileSizeNumber?: number;
  }
  
  interface Folder {
    fileType: string;
    fullName: string;
    name: string;
  }
  
  interface FilesState {
    selectedFolder: string;
    files: File[];
    filesSorted: File[];
    folders: Folder[];
    categorie_size_image: number;
    categorie_size_documents: number;
    categorie_size_videos: number;
    totalFilesSize: number;
  }
  
  interface State {
    user: {
      email: string;
    };
    files: FilesState;
  }
  
  // Mutations
  export default {
    storeFiles(state: State, files: File[]): void {
      if (state.files.selectedFolder) {
        state.files.filesSorted = files.filter(
          (file) => file.fileType === 'file' && file.fullName.includes(state.files.selectedFolder)
        );
      } else {
        state.files.filesSorted = files.filter(
          (file) => file.fileType === 'file' && file.fullName.split('/').length - 1 <= 2
        );
      }
      state.files.files = files.filter((file) => file.fileType === 'file');
      this.commit('storeFileTotalSize');
    },
  
    storeFolders(state: State, files: File[]): void {
      state.files.folders = files.filter((file) => file.fileType === 'folder');
    },
  
    addFolderToStore(state: State, folderName: string): void {
      const folderToAdd: Folder = {
        fileType: 'folder',
        fullName: `${state.user.email}/${folderName}`,
        name: folderName,
      };
      state.files.folders.push(folderToAdd);
    },
  
    removeFolderFromStore(state: State, folderName: string): void {
      const folderIndex = state.files.folders.findIndex((folder) => folder.name === folderName);
      if (folderIndex !== -1) {
        state.files.folders.splice(folderIndex, 1);
      }
      state.files.selectedFolder = 'Hovedfolder';
    },
  
    removeFileFromStore(state: State, fileName: string): void {
      state.files.filesSorted = state.files.filesSorted.filter((file) => file.fullName !== fileName);
      state.files.files = state.files.files.filter((file) => file.fullName !== fileName);
      this.commit('storeFilesCategories', state.files.files);
      this.commit('storeFileTotalSize');
    },
  
    moveFileToFolder(
      state: State,
      payload: { filePayload: { srcFilename: string; destFilename: string }; result: any }
    ): void {
      // Remove the file from the current filesSorted list
      state.files.filesSorted = state.files.filesSorted.filter(
        (file) => file.fullName !== payload.filePayload.srcFilename
      );
  
      // Update the file's fullName in the general files array
      state.files.files.forEach((file) => {
        if (file.fullName === payload.filePayload.srcFilename) {
          file.fullName = payload.filePayload.destFilename;
        }
      });
  
      // Reapply the filtering logic for filesSorted to reflect the new state
      this.commit('storeFiles', state.files.files);
    },
  
    setSelectedFolder(state: State, folderName: string): void {
      state.files.selectedFolder = folderName;
      state.files.filesSorted = state.files.files.filter(
        (file) => file.fileType === 'file' && file.fullName.split('/').length - 1 <= 2
      );
    },
  
    storeFilesCategories(state: State, files: File[]): void {
      const imageIcons = ['pi pi-image'];
      const documentIcons = ['pi pi-file-pdf', 'pi pi-file'];
      const videoIcons = ['pi pi-video'];
  
      state.files.categorie_size_image = files.filter((file) => imageIcons.includes(file.icon || '')).length;
      state.files.categorie_size_documents = files.filter((file) => documentIcons.includes(file.icon || '')).length;
      state.files.categorie_size_videos = files.filter((file) => videoIcons.includes(file.icon || '')).length;
    },
  
    storeFileTotalSize(state: State): void {
      let totalBytes = 0;
  
      state.files.files.forEach((file) => {
        if (file.fileSizeNumber !== undefined || file.fileSizeNumber === 0) {
          totalBytes += Number(file.fileSizeNumber);
        }
      });
  
      const bytesToMb = Number(totalBytes / 1048576);
  
      state.files.totalFilesSize = bytesToMb;
    },
  
    // Folders
    selectFilesInFolder(state: State, folderName: string): void {
      state.files.selectedFolder = folderName;
      state.files.filesSorted = state.files.files.filter(
        (file) => file.fileType === 'file' && file.fullName.includes(folderName)
      );
    },
  };
  
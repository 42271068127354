// Helper function to calculate trend percentage and determine color
export function calculateTrendResult(trendPercentage) {
    const color = trendPercentage > 0
        ? 'text-green-500'
        : trendPercentage < 0
            ? 'text-red-500'
            : 'text-black';

    return trendPercentage === null
        ? { value: null, color }
        : { value: `${trendPercentage > 0 ? '+' : ''}${Math.round(trendPercentage)}%`, color };
}

export function calculateTotalClicksOnAd(campaigns, selectedTimePeriod) {
    let totalClicks = 0;

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.data) {
            const data = campaign.insights.data;
            const daysToSum = selectedTimePeriod.value === 'maximum' ? data.length : selectedTimePeriod.sumDays;
            const relevantData = data.slice(-daysToSum);

            relevantData.forEach(entry => {
                const linkClickAction = entry.actions.find(action => action.action_type === 'link_click');
                totalClicks += linkClickAction ? parseInt(linkClickAction.value, 10) : 0;
            });
        }
    });

    return totalClicks;
}

export function calculatePriorTotalClicksOnAd(campaigns, selectedTimePeriod) {
    let priorTotalClicks = 0;
    const { sumDays } = selectedTimePeriod;
    const daysToSkip = sumDays;

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.data) {
            const data = campaign.insights.data;
            const relevantData = data.slice(-sumDays - daysToSkip, -daysToSkip);

            relevantData.forEach(entry => {
                const linkClickAction = entry.actions.find(action => action.action_type === 'link_click');
                priorTotalClicks += linkClickAction ? parseInt(linkClickAction.value, 10) : 0;
            });
        }
    });

    return priorTotalClicks;
}

export function calculateTotalClicksOnAdTrendPercentage(campaigns, selectedTimePeriod) {
    let startPeriodTotalClicks = 0;
    let endPeriodTotalClicks = 0;

    const periodLength = selectedTimePeriod.sumDays;
    const dataSliceSize = periodLength * 2;

    for (const campaign of campaigns) {
        if (campaign.insights && campaign.insights.data) {
            const data = campaign.insights.data;
            if (data.length >= dataSliceSize) {
                const startPeriodData = data.slice(-dataSliceSize, -periodLength);
                const endPeriodData = data.slice(-periodLength);

                startPeriodData.forEach(entry => {
                    const linkClickAction = entry.actions.find(action => action.action_type === 'link_click');
                    startPeriodTotalClicks += linkClickAction ? parseInt(linkClickAction.value, 10) : 0;
                });

                endPeriodData.forEach(entry => {
                    const linkClickAction = entry.actions.find(action => action.action_type === 'link_click');
                    endPeriodTotalClicks += linkClickAction ? parseInt(linkClickAction.value, 10) : 0;
                });
            }
        }
    }

    const trendPercentage = startPeriodTotalClicks !== 0
        ? ((endPeriodTotalClicks - startPeriodTotalClicks) / startPeriodTotalClicks) * 100
        : 0;

    return calculateTrendResult(trendPercentage);
}

export function calculateTotalClicksOnAdByDate(campaigns) {
    const clicksOnAdByDate = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.data) {
            campaign.insights.data.forEach(entry => {
                const linkClicks = entry.actions.find(action => action.action_type === 'link_click')?.value || 0;
                clicksOnAdByDate[entry.date_start] = (clicksOnAdByDate[entry.date_start] || 0) + parseInt(linkClicks, 10);
            });
        }
    });

    return Object.keys(clicksOnAdByDate)
        .map(date => ({ date, clicksOnAd: clicksOnAdByDate[date] }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));
}

export function calculateTotalClicksOnAdInWeeks(campaigns) {
    const clicksByWeek = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.dataInWeeks) {
            campaign.insights.dataInWeeks.forEach(entry => {
                const week = entry.date_start;
                const linkClickAction = entry.actions.find(action => action.action_type === "link_click");
                const clicksOnAd = linkClickAction ? parseInt(linkClickAction.value, 10) : 0;
                clicksByWeek[week] = (clicksByWeek[week] || 0) + clicksOnAd;
            });
        }
    });

    return Object.keys(clicksByWeek)
        .map(week => ({ week, clicksOnAd: clicksByWeek[week] }))
        .sort((a, b) => a.week.localeCompare(b.week));
}

export function calculateTotalClicksOnAdInMonths(campaigns) {
    const clicksByMonth = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.dataInMonths) {
            campaign.insights.dataInMonths.forEach(entry => {
                const month = entry.date_start;
                const linkClickAction = entry.actions.find(action => action.action_type === "link_click");
                const linkClicks = linkClickAction ? parseInt(linkClickAction.value, 10) : 0;
                clicksByMonth[month] = (clicksByMonth[month] || 0) + linkClicks;
            });
        }
    });

    return Object.keys(clicksByMonth)
        .map(month => ({ month, clicksOnAd: clicksByMonth[month] }))
        .sort((a, b) => a.month.localeCompare(b.month));
}

export function calculateTotalClicksOnAdInYears(campaigns) {
    const clicksByYear = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.dataInYears) {
            campaign.insights.dataInYears.forEach(entry => {
                const year = entry.date_start;
                const linkClickAction = entry.actions.find(action => action.action_type === "link_click");
                const linkClicks = linkClickAction ? parseInt(linkClickAction.value, 10) : 0;
                clicksByYear[year] = (clicksByYear[year] || 0) + linkClicks;
            });
        }
    });

    return Object.keys(clicksByYear)
        .map(year => ({ year, clicksOnAd: clicksByYear[year] }))
        .sort((a, b) => a.year.localeCompare(b.year));
}

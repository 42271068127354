<template>
    <MMDialog :visible="showModal" @update:visible="closeModal" width="80vw" maxWidth="90vw" maxHeight="90vh">
        <template #header>
            <button class="close-button" @click="closeModal">&times;</button>
        </template>
        <div class="image-gallery">
            <!-- Image display -->
            <div class="image-container">
                <img :src="currentImage" alt="Gallery Image" />
            </div>

            <!-- Large clickable areas -->
            <div class="navigation-area left" @click="prevImage"></div>
            <div class="navigation-area right" @click="nextImage"></div>

            <!-- Navigation arrows -->
            <button v-if="images.length > 1" class="arrow left" @click="prevImage">
                &#8249;
            </button>
            <button v-if="images.length > 1" class="arrow right" @click="nextImage">
                &#8250;
            </button>
        </div>
    </MMDialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import MMDialog from "@/components/overlay/MMDialog.vue"; // Import your MMDialog component

const props = defineProps({
    showModal: Boolean, // Determines whether the modal is visible
    images: {
        type: Array as () => string[], // Array of image URLs
        required: true,
    },
    initialIndex: {
        type: Number,
        default: 0, // Default starting image index
    },
});

const emit = defineEmits(["close"]);

const currentIndex = ref(props.initialIndex);

// Computed property for the current image
const currentImage = computed(() => props.images[currentIndex.value]);

// Close the modal
const closeModal = () => {
    emit("close");
};

// Navigate to the previous image
const prevImage = () => {
    if (currentIndex.value > 0) {
        currentIndex.value--;
    } else {
        currentIndex.value = props.images.length - 1; // Loop to the last image
    }
};

// Navigate to the next image
const nextImage = () => {
    if (currentIndex.value < props.images.length - 1) {
        currentIndex.value++;
    } else {
        currentIndex.value = 0; // Loop to the first image
    }
};
</script>

<style scoped>
.image-gallery {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.image-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* Navigation areas for large clickable zones */
.navigation-area {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%; /* Cover half the modal */
    z-index: 5; /* Place above the image but below the arrows */
    cursor: pointer;
}

.navigation-area.left {
    left: 0;
}

.navigation-area.right {
    right: 0;
}

/* Navigation arrows */
.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.arrow.left {
    left: 10px;
}

.arrow.right {
    right: 10px;
}

/* Hover effects for navigation areas */
.navigation-area.left:hover ~ .arrow.left {
    background: rgba(0, 0, 0, 0.8);
}

.navigation-area.right:hover ~ .arrow.right {
    background: rgba(0, 0, 0, 0.8);
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: black;
    cursor: pointer;
}
</style>


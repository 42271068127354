<script setup>
import { defineProps, defineEmits, computed, watch, onBeforeUnmount, ref } from "vue";

const props = defineProps({
    visible: {
        type: Boolean,
        required: true,
    },
    width: {
        type: String,
        default: "600px",
    },
    height: {
        type: String,
        default: "auto",
    },
    maxWidth: {
        type: String,
        default: "90vw",
    },
    minWidth: {
        type: String,
        default: "300px",
    },
    maxHeight: {
        type: String,
        default: "90vh",
    },
    minHeight: {
        type: String,
        default: "200px",
    },
    closeOnEscape: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits(["close", "update:visible"]);

const contentStyle = computed(() => ({
    width: props.width,
    height: props.height,
    maxWidth: props.maxWidth,
    minWidth: props.minWidth,
    maxHeight: props.maxHeight,
    minHeight: props.minHeight,
}));

const isVisible = ref(false);

watch(
    () => props.visible,
    (newVal) => {
        if (newVal) {
            isVisible.value = true; // Show the overlay immediately
        } else {
            setTimeout(() => {
                isVisible.value = false; // Delay hiding until animation ends
            }, 300); // Match the CSS animation duration
        }
    },
    { immediate: true }
);

const close = () => {
    emit("update:visible", false);
    emit("close");
};

const handleOverlayClick = () => {
    close();
};

const handleKeydown = (event) => {
    if (props.closeOnEscape && event.key === "Escape") {
        close();
    }
};

const toggleBodyScroll = (disable) => {
    document.body.style.overflow = disable ? "hidden" : "";
};

watch(
    () => props.visible,
    (isVisible) => {
        toggleBodyScroll(isVisible);
        if (isVisible) {
            document.addEventListener("keydown", handleKeydown);
        } else {
            document.removeEventListener("keydown", handleKeydown);
        }
    },
    { immediate: true }
);

onBeforeUnmount(() => {
    toggleBodyScroll(false);
    document.removeEventListener("keydown", handleKeydown);
});
</script>

<template>
    <div v-show="isVisible" class="dialog-overlay" @click="handleOverlayClick">
        <div class="dialog-content" :class="{ 'fade-in': props.visible, 'fade-out': !props.visible }"
            :style="contentStyle" @click.stop>
            <header class="dialog-header" v-if="$slots.header">
                <slot name="header">
                    <button @click="close">Close</button>
                </slot>
            </header>
            <main class="dialog-body">
                <slot></slot>
            </main>
            <footer class="dialog-footer" v-if="$slots.footer">
                <slot name="footer"></slot>
            </footer>
        </div>
    </div>
</template>

<style scoped>
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    animation: overlay-fade-in 0.15s forwards;
    /* Overlay fade-in animation */
}

.dialog-content {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: auto;
    max-height: 90vh;
    max-width: 90vw;
    transform: scale(0.9);
    opacity: 0;
    transition: transform 0.15s ease, opacity 0.15s ease;
}

.dialog-content.fade-in {
    transform: scale(1);
    opacity: 1;
}

.dialog-content.fade-out {
    transform: scale(0.9);
    opacity: 0;
}

.dialog-header,
.dialog-footer {
    padding: 1rem;
}

.dialog-body {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    box-sizing: border-box;
}

@keyframes overlay-fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>

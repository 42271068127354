import axios from '../../axios-auth';

// Enums
import { UserTypeEnum } from '@/datastructures/enums/users/UserTypeEnum';

// Utils
import { withTokenCheck } from '@/utils/auth/tokenChecker';

// Service
import { LocalStorageService } from '@/service/localStorage/LocalStorageService';

// Initialize
const localStorageService = new LocalStorageService();

const routePath = '/user';

export default {
  storeUser: withTokenCheck(({ state }, userData) => {
    if (!state.token) {
      return;
    }
    axios
      .get('/auth/me')
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }),

  updateUserSubscription: withTokenCheck(
    async (
      {},
      { userId, subscriptionVisualName, subscriptionPlanName, priceId, status, stripeSubscriptionId, stripeCustomerId }
    ) => {
      return axios
        .post(`${routePath}/update_user_subscription`, {
          userId,
          subscriptionVisualName,
          subscriptionPlanName,
          priceId,
          status,
          stripeSubscriptionId,
          stripeCustomerId,
        })
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          console.log('ERROR updating user subscription');
        });
    }
  ),

  attachPaymentMethod: withTokenCheck(async ({}, { customerId, paymentMethodId }) => {
    return axios
      .post(`${routePath}/attachPaymentMethod`, {
        customerId,
        paymentMethodId,
      })
      .then((res) => {
        console.log('Result from attachPaymentMethod');
        console.log(res);
        return res;
      })
      .catch(() => {
        console.log('ERROR attaching payment method');
      });
  }),

  changeUserPassword: withTokenCheck(async ({ state }, { currentPassword, newPassword }) => {
    return axios
      .put(`${routePath}/update_password/${state.user.uuid}`, { currentPassword, newPassword })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error.response.data.error; // Throw the error message from the backend
      });
  }),

  changeUserEmail: withTokenCheck(async ({ state }, { newEmail, password }) => {
    return axios
      .put(`${routePath}/update_email/${state.user.uuid}`, { newEmail, password })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error.response.data.error; // Throw the error message from the backend
      });
  }),

  deleteUser: withTokenCheck(async ({ state }) => {
    return axios
      .delete(`${routePath}/${state.user.uuid}`)
      .then((res) => {
        return res;
      })
      .catch(() => {
        console.log('ERROR deleting user');
      });
  }),

  updateUserType: withTokenCheck(({ commit, state }, payload) => {
    console.log('updateUserType: withTokenCheck is hit WITH custom payload');
    axios
      .put(`/user/${state.user.uuid}`, {
        userType: payload.userType,
        companyName: payload.companyName,
      })
      .then((res) => {
        commit('storeUser', res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }),

  fetchUser: withTokenCheck(async ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/auth/me')
        .then((res: any) => {
          const user = res.data.data;

          if (user.userType === UserTypeEnum.Agency) {
            dispatch('fetchContacts');
          }

          dispatch('getMessages', res.data.data.uuid);
          commit('storeUser', res.data.data);
          resolve(res.data);
        })
        .catch((error: any) => {
          reject(error); // Reject with error
        });
    });
  }),

  updateUserProfile: withTokenCheck(({ commit, state }, userData) => {
    axios
      .put(`/user/${state.user.uuid}`, userData)
      .then((res) => {
        console.log('Response from updateUserProfile: ', res.data.data);
        commit('storeUser', res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }),

  patchUser: withTokenCheck(async ({ commit, state }, payload) => {
    console.log('patchUser: withTokenCheck is hit WITH custom payload');
    console.log('payload:', payload);
    try {
      const response = await axios.patch(`/user/${state.user.uuid}`, payload);

      // Commit updated user data to the store
      commit('storeUser', response.data.data);

      console.log('User updated successfully:', payload);
      return response.data;
    } catch (error) {
      console.error('Failed to patch user:', error);
      throw error; // Allow the caller to handle the error
    }
  }),

  disconnectPlatform: withTokenCheck(({ commit, state }, platform) => {
    return new Promise((resolve, reject) => {
      let body;

      if (platform === 'facebookPages') {
        localStorageService.removeItem('meta-selectedPage');
        body = {
          'platformCredentials.meta.data.pages.connected': false,
          'platformCredentials.meta.data.pages.data': [],
        };
      } else if (platform === 'facebookAdAccounts') {
        localStorageService.removeItem('meta-selectedAdAccount');
        body = {
          'platformCredentials.meta.data.adAccounts.connected': false,
          'platformCredentials.meta.data.adAccounts.data': [],
        };
      } else {
        return reject(new Error('Unknown platform'));
      }

      axios
        .patch(`/user/${state.user.uuid}`, body)
        .then((res) => {
          console.log(
            `ACTIONS --- SUCCESS -- disconnectedPlatform - Successfully disconnected ${platform}:`,
            res.data.data
          );

          const updatedUser = res.data.data;
          if (updatedUser.platformCredentials.meta.data.pages.connected === false) {
            commit('resetFacebookPages');
          }

          commit('storeUser', res.data.data); // Update the user in the store
          resolve(res.data); // Resolve with the response data
        })
        .catch((error) => {
          console.error(`Failed to disconnect ${platform}:`, error);
          reject(error); // Reject with the error
        });
    });
  }),
};

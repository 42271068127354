// Getters
import marketingDataGetters from '../marketing/marketingDataGetters';

// Interfaces
import { IUser } from '@/datastructures/interfaces/users/IUser';

export interface RootState {
  user: IUser; // Assume IUser is your defined user model
  otherModuleState?: any; // Add additional modules or state fields as needed
}

export default {
  user(state: RootState) {
    return state.user;
  },
  getUserEmail(state: RootState) {
    return state.user.email;
  },
  getUserUUID(state: RootState) {
    return state.user.uuid;
  },

  getUserMetaCredentials(state: RootState) {
    return state.user.platformCredentials.meta?.data;
  },

  getStoredInstagramId(state: RootState) {
    return (
      state.user.platformCredentials.meta?.data?.pages?.data?.find((page) => page.instagram?.id)?.instagram?.id || null
    );
  },

  getFeatureToggles(state: RootState) {
    return state.user.featureToggles;
  },

  ...marketingDataGetters,
};

interface PartnerEmails {
  email: string;
  businessPartnerEmail: string | null;
}

export function checkForSelectedPartner(email: string): PartnerEmails {
  const businessEmail = localStorage.getItem('businessDataOnBehalfOf_Email');

  if (businessEmail) {
    return {
      email: email,
      businessPartnerEmail: businessEmail,
    };
  } else {
    return {
      email: email,
      businessPartnerEmail: null,
    };
  }
}

export function checkForSelectedPartnerAndReturnEmail(email: string): string {
  const businessEmail = localStorage.getItem('businessDataOnBehalfOf_Email');
  return businessEmail || email;
}

export function checkForSelectedPartnerAndReturnUUID(uuid: string): string {
  const partnerUUID = localStorage.getItem('businessDataOnBehalfOf_UUID');
  return partnerUUID || uuid;
}

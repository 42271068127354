import axios from '../../axios-auth';
import { checkForSelectedPartnerAndReturnUUID } from '@/service/users/userCheck';

interface State {
  token: string | null;
  user: {
    uuid: string;
  };
}

interface EventData {
  uuid?: string;
  [key: string]: any;
}

interface Commit {
  (mutation: string, payload: any): void;
}

export default {
  async getCalendar({ state, commit }: { state: State; commit: Commit }): Promise<any> {
    if (!state.token) {
      return Promise.reject(new Error('No token available'));
    }

    try {
      const res = await axios.get(`/marketingCalendar/${checkForSelectedPartnerAndReturnUUID(state.user.uuid)}`);
      commit('storeCalendar', res.data);
      return res.data; // Return the data to allow for awaiting the result
    } catch (error) {
      console.log('action error', error);
      return Promise.reject(error);
    }
  },

  async addCalendarData({ state, commit }: { state: State; commit: Commit }, eventData: EventData): Promise<any> {
    if (!state.token) {
      return Promise.reject(new Error('No token available'));
    }

    console.log('formData', eventData);

    const formData = new FormData();

    for (const key in eventData) {
      if (key === 'media' && Array.isArray(eventData.media)) {
        eventData.media.forEach((file) => {
          formData.append('media', file); // Append each file with the field name 'media'
        });
      } else {
        formData.append(key, JSON.stringify(eventData[key])); // Append other fields as JSON strings
      }
    }

    try {
      const res = await axios.post(
        `/marketingCalendar/${checkForSelectedPartnerAndReturnUUID(state.user.uuid)}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      commit('storeMarketingCalendar', res.data.marketingCalendar);
      return res.data.marketingCalendar;
    } catch (error) {
      console.log('action error', error);
      return Promise.reject(error);
    }
  },

  async patchCalendarData({ state, commit }: { state: State; commit: Commit }, eventData: EventData): Promise<any> {
    if (!state.token) {
      return Promise.reject(new Error('No token available'));
    }

    try {
      const userUUID = checkForSelectedPartnerAndReturnUUID(state.user.uuid);
      const formData = new FormData();

      for (const key in eventData) {
        if (key === 'media' && Array.isArray(eventData.media)) {
          if (eventData.media.length > 0) {
            eventData.media.forEach((file) => {
              formData.append('media', file);
            });
          } else {
            formData.append('media', ''); // Empty field for media
          }
        } else if (key === 'uuid') {
          formData.append(key, eventData[key]);
        } else {
          formData.append(key, JSON.stringify(eventData[key]));
        }
      }

      const res = await axios.patch(`/marketingCalendar/${userUUID}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      commit('patchCalendarEvent', res.data.updatedEvent);
      return res.data.marketingCalendar;
    } catch (error) {
      console.log('action error', error);
      return Promise.reject(error);
    }
  },

  async deleteCalendarData({ state, commit }: { state: State; commit: Commit }, uuid: string): Promise<any> {
    if (!state.token) {
      return Promise.reject(new Error('No token available'));
    }

    try {
      const userUUID = checkForSelectedPartnerAndReturnUUID(state.user.uuid);

      const res = await axios.delete(`/marketingCalendar/${userUUID}?eventUUID=${uuid}`);
      commit('deleteCalendarEvent', res.data);
      return res.data.marketingCalendar;
    } catch (error) {
      console.log('action error', error);
      return Promise.reject(error);
    }
  },
};

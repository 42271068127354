<script setup>
import { ref, computed, watch, onMounted, defineAsyncComponent } from "vue";

// Components
const WelcomeModal = defineAsyncComponent(() => import('./pages/auth/onboarding-modals/WelcomeModal.vue'));
const WelcomeModalGoogleLogin = defineAsyncComponent(() => import('./pages/auth/onboarding-modals/WelcomeModalGoogleLogin.vue'));

import MMToast from '@/components/messages/MM-Toast.vue';

// Store
import { useStore } from "vuex";
const store = useStore();

// References
const title = ref("");
const message = ref("");
const group = ref("");
const showModal = ref(false);
const isLoading = ref(true);
const isFadingOut = ref(false);

// Computed 
const toast = computed(() => {
    if (store.state.toast || store.state.toast !== null) {
        return store.state.toast;
    } else {
        return null;
    }
});

const username = computed(() => store.state.user.name);

// Watchers
watch(toast, async (newValue) => {
    if (newValue) {
        title.value = newValue.title;
        message.value = newValue.message;
        group.value = newValue.group;
    }
}, { immediate: true });

watch(() => store.state.user.userType, (newUserType) => {
    setTimeout(() => {
        if (newUserType === "undefined") {
            showModal.value = true;
        }
    }, 600);
}, { immediate: true });

// Lifecycle hooks
onMounted(() => {
    const authUserPromise = store.dispatch("getAuthUser");
    authUserPromise
        .then(response => {
            store.dispatch("getNotifications")

            if (response.data.userType == "undefined") {
                showModal.value = true;
            }

            if (response.data.profile === undefined) {
                store.dispatch('getProfileLogo', response.data.email)
                    .catch(error => {
                        console.error('Error getting getProfileLogo:', error);
                    });
            }
        })
        .catch((error) => {
            console.error("Error fetching user: " + error);
        })
        .finally(() => {
            isFadingOut.value = true; // Start fade-out animation
            setTimeout(() => {
                isLoading.value = false; // Remove preloader completely after fade-out
                isFadingOut.value = false; // Reset fade-out state
            }, 300); // Match this timeout to the CSS animation duration
        });

    store.dispatch("initializeWebSocket");
    store.commit('storebusinessDataOnBehalfOf_UUID', localStorage.getItem('businessDataOnBehalfOf_UUID'))
    store.commit('storebusinessDataOnBehalfOf_Email', localStorage.getItem('businessDataOnBehalfOf_Email'))
    store.commit('storebusinessDataOnBehalfOf_Name', localStorage.getItem('businessDataOnBehalfOf_Name'))
});


// Functions 
function handleUserTypeSelection(payload) {
    store.dispatch('updateUserType', payload)
        .then(() => {
            showModal.value = false;
        })
        .catch(() => {
            console.error('Error updating user type');
        });
}
</script>

<template>
    <div v-if="isLoading || isFadingOut" :class="['layout-preloader-container', { 'fade-out': isFadingOut }]">
        <div class="layout-preloader">
            <span></span>
        </div>
    </div>


    <MMToast />

    <WelcomeModalGoogleLogin v-if="store.state.user.companyName == ''" :visible="showModal" :username="username"
        @update:visible="val => showModal = val" @handleUserTypeSelection="handleUserTypeSelection" />

    <WelcomeModal v-else :visible="showModal" :username="username" @update:visible="val => showModal = val"
        @handleUserTypeSelection="handleUserTypeSelection" />
</template>

<style scoped>
.layout-preloader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #111417;
    z-index: 9999;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.layout-preloader-container.fade-out {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
}
</style>

import axios from '../../axios-auth';
import { checkForSelectedPartnerAndReturnUUID } from '@/service/users/userCheck';

// Storage route path
const routePath = '/storage';

// Define interfaces for the state and actions
interface State {
  token: string | null;
  user: {
    uuid: string;
  };
}

interface File {
  name: string;
  fullName: string;
  fileType: string;
}

interface Folder {
  folderName: string;
}

interface FilePayload {
  srcFilename: string;
  destFilename: string;
  userUUID: string;
}

// Vuex action context type
interface ActionContext<S> {
  state: S;
  commit: (mutation: string, payload?: any) => void;
  dispatch: (action: string, payload?: any) => Promise<any>;
}

export default {
  async getFiles({ commit }: ActionContext<State>, uuid: string): Promise<void> {
    try {
      const res = await axios.get(`${routePath}/folder/${uuid}`);
      commit('storeFiles', res.data.files);
      commit('storeFolders', res.data.files);
      commit('storeFilesCategories', res.data.files);
      commit('storeFileTotalSize', res.data.totalFileSize);
    } catch (error) {
      console.error('action error', error);
    }
  },

  async getFileUrl({ state }: ActionContext<State>, fileName: string): Promise<string | undefined> {
    if (!state.token) return;

    try {
      const res = await axios.get(`${routePath}/fileUrl?fileName=${fileName}`);
      return res.data.url;
    } catch (error) {
      console.error('action error', error);
    }
  },

  async uploadFile(
    { state, dispatch }: ActionContext<State>,
    formData: FormData
  ): Promise<void> {
    try {
      await axios.post(`${routePath}/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await dispatch('getFiles', checkForSelectedPartnerAndReturnUUID(state.user.uuid));
    } catch (error) {
      console.error('action error', error);
    }
  },

  async downloadFile({ state }: ActionContext<State>, file: File): Promise<void> {
    if (!state.token) return;

    try {
      const res = await axios.get(`${routePath}/download?fileName=${file.fullName}`, {
        responseType: 'blob',
      });
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      throw error;
    }
  },

  async moveFileToFolder(
    { commit, dispatch }: ActionContext<State>,
    filePayload: FilePayload
  ): Promise<void> {
    try {
      console.log("Dispatching moveFileToFolder with payload:", filePayload); // Debug

      // Make the API call to move the file
      const result = await axios.post(`${routePath}/moveFile`, filePayload);
      console.log("Move file response:", result); // Debug

      // Commit the mutation to update the local state
      commit('moveFileToFolder', { filePayload, result });
  
      // Refresh the files list to ensure the frontend reflects the backend state
      await dispatch('getFiles', filePayload.userUUID);
    } catch (error) {
      console.error('Error moving file:', error);
      throw error; // Propagate error for upstream handling
    }
  },
  

  async deleteFile({ state, commit }: ActionContext<State>, fileName: string): Promise<void> {
    if (!state.token) return;

    try {
      await axios.delete(`${routePath}/file?fileName=${fileName}`);
      commit('removeFileFromStore', fileName);
    } catch (error) {
      console.error('action error', error);
    }
  },

  // Folders
  async createFolder({ state, commit }: ActionContext<State>, folderName: string): Promise<void> {
    const userUUID = checkForSelectedPartnerAndReturnUUID(state.user.uuid);
    try {
      await axios.post(`${routePath}/folder/${userUUID}?folderName=${folderName}`);
      commit('addFolderToStore', folderName);
    } catch (error) {
      console.error('action error', error);
    }
  },

  async deleteFolderAndContent(
    { state, commit, dispatch }: ActionContext<State>,
    data: Folder
  ): Promise<any> {
    const validatedUUID = checkForSelectedPartnerAndReturnUUID(state.user.uuid);

    try {
      const res = await axios.delete(`${routePath}/folder/${validatedUUID}?folderName=${data.folderName}`);
      await dispatch('getFiles', validatedUUID); // Wait for files to refresh
      commit('removeFolderFromStore', data.folderName);
      return res;
    } catch (error) {
      console.error('action error', error);
      throw error;
    }
  },
};

import axios from '../../../axios-auth';

// Define the types for your data structures
interface InsightParams {
  time_range?: { since: string; until: string };
  time_increment: string;
  fields: string[];
  date_preset?: string;
}

interface CampaignData {
  insightParams: InsightParams;
  selectedTimePeriod: string[];
  lastSelectedAdAccountID: string;
}

interface MetaCredentials {
  accessToken: string;
}

interface Getters {
  getUserMetaCredentials: MetaCredentials;
}

interface Commit {
  (mutation: string, payload: any): void;
}

interface Dispatch {
  (action: string, payload: any): Promise<void>;
}

// Define the module
const routePath = '/meta';

export default {
  getAdAccountCampaigns(
    { commit, getters }: { commit: Commit; getters: Getters; },
    data: CampaignData
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const { accessToken } = getters.getUserMetaCredentials;
      const {
        insightParams: { time_range, time_increment, fields, date_preset },
        selectedTimePeriod,
        lastSelectedAdAccountID,
      } = data;

      const params = {
        selectedTimePeriod: JSON.stringify(selectedTimePeriod),
        access_token: accessToken,
        time_increment,
        fields: String(fields),
        ...(date_preset ? { date_preset } : { time_range }),
      };

      axios
        .get(`${routePath}/getCampaigns`, {
          params: {
            ...params,
            ad_account_id: lastSelectedAdAccountID,
          },
        })
        .then((res: any) => {
          const response = res.data;

          console.log('Response from getAdAccountCampaigns:', response);

          // Commit campaign data
          commit('storeMetaCampaigns', response.campaigns);
          commit('storeTotalSpend', response);
          commit('calculateAndStoreTotalSpend', response.campaigns);
          
          commit('storeTotalClickGeneralMetrics', response);
          commit('updateAdCampaignClickMetrics', response.campaigns);
          commit('updateAdCampaignCPCMetrics', response.campaigns);
          commit('updateImpressionsGeneralMetric', response.generalMetrics);

          // Update fetch tracking state
          commit('updateFetchTracking', {
            platform: 'meta',
            status: true,
          });

          // Resolve the promise indicating success
          resolve();
        })
        .catch((error: any) => {
          console.error('Error fetching ad account campaigns:');
          console.log(error);

          // Update fetch tracking state with failure
          commit('updateFetchTracking', {
            platform: 'meta',
            status: false,
          });

          // Reject the promise if there's an error
          reject(error);
        });
    });
  },

  async fetchMetaCampaignInsights(
    { commit, getters, dispatch }: { commit: Commit; getters: Getters; dispatch: Dispatch },
    data: CampaignData
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const { accessToken } = getters.getUserMetaCredentials;
      const {
        insightParams: { time_range, time_increment, fields, date_preset },
        selectedTimePeriod,
        lastSelectedAdAccountID,
      } = data;

      const params = {
        selectedTimePeriod: JSON.stringify(selectedTimePeriod),
        access_token: accessToken,
        time_increment,
        fields: String(fields),
        ...(date_preset ? { date_preset } : { time_range }),
      };

      // console.log('---- start ----');
      // console.log('accesToken: ' + accessToken);
      // console.log('time_increment: ' + time_increment);
      // console.log('fields: ' + fields);
      // console.log('date_preset: ' + date_preset);
      // console.log('time_range: ' + time_range);
      // console.log('selectedTimePeriod: ' + selectedTimePeriod);
      // console.log('lastSelectedAdAccountID: ' + lastSelectedAdAccountID);
      // console.log('params: ' + JSON.stringify(params));
      // console.log('---- end ----');

      axios
        .get(`${routePath}/getcampaignssimple`, {
          params: {
            ...params,
            ad_account_id: lastSelectedAdAccountID,
          },
        })
        .then((res: any) => {
          const response = res.data;
          resolve(response);
        })
        .catch((error: any) => {
          console.error('Error fetching ad account campaigns:');
          console.log(error);
          reject(error);
        });
    });
  },
};

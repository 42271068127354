<script setup lang="ts">
import { ref, watch, computed, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleMessages } from "@/utils/locales/useLocaleMessages";

// Props
const props = defineProps({
    links: {
        type: Array,
        default: () => [],
    },
    editing: Boolean,
});

// Emits
const emit = defineEmits(["update:links"]);

// Translations
const { t } = useI18n();
useLocaleMessages({
    da: () => import("@/locales/da/views/contacts/contactLinkView.json"),
    en: () => import("@/locales/en/views/contacts/contactLinkView.json"),
});

// Refs
const linkList = ref([...(props.links || [])]);
const newLink = ref({ title: "", link: "" });
const selectedIndex = ref(-1);
const draggedItemIndex = ref<number | null>(null);

// Computed
const isEditing = computed(() => props.editing);

// Functions
const onDragStart = (event: DragEvent, index: number) => {
    draggedItemIndex.value = index;
    event.dataTransfer?.setData("text/plain", index.toString());
};
// Function to handle drag over
const onDragOver = (event: DragEvent) => {
    event.preventDefault(); // Allow drop
};
const onDrop = (event: DragEvent, targetIndex: number) => {
    event.preventDefault();
    const sourceIndex = draggedItemIndex.value;
    if (sourceIndex === null || sourceIndex === targetIndex) return;

    // Reorder the linkList
    const movedItem = linkList.value[sourceIndex];
    linkList.value.splice(sourceIndex, 1);
    linkList.value.splice(targetIndex, 0, movedItem);

    draggedItemIndex.value = null;
};
const onDragEnd = () => {
    draggedItemIndex.value = null;
};

const saveLinks = () => {
    emit("update:links", linkList.value);
};

const addLink = () => {
    if (newLink.value.title.trim() && newLink.value.link.trim()) {
        linkList.value.push({ ...newLink.value });
        newLink.value = { title: "", link: "" };
        saveLinks();
    }
};

const validateNewLink = () => {
    return newLink.value.title.trim() && newLink.value.link.trim();
};

const persistNewLinkIfNeeded = () => {
    if (validateNewLink()) {
        linkList.value.push({ ...newLink.value });
        newLink.value = { title: "", link: "" };
    }
};

const removeLink = (index: number) => {
    linkList.value.splice(index, 1);
    saveLinks();
};

const getIconSrc = (title: string): string => {
    const lowerTitle = title.toLowerCase();
    if (lowerTitle === "x") {
        return "/images/icons/platforms/icons8-x-96.png";
    } else if (lowerTitle.includes("meta") || lowerTitle.includes("facebook")) {
        return "/images/icons/platforms/icons8-meta-96.png";
    } else if (lowerTitle.includes("instagram")) {
        return "/images/icons/platforms/icons8-instagram-96.png";
    } else if (lowerTitle.includes("canva")) {
        return "/images/icons/platforms/icons8-canva-app-96.png";
    } else if (lowerTitle.includes("linkedin")) {
        return "/images/icons/platforms/icons8-linkedin-96.png";
    } else if (lowerTitle.includes("tiktok")) {
        return "/images/icons/platforms/icons8-tiktok-96.png";
    } else if (lowerTitle.includes("snapchat")) {
        return "/images/icons/platforms/icons8-snapchat-96.png";
    } else if (lowerTitle.includes("youtube")) {
        return "/images/icons/platforms/icons8-youtube-100.png";
    } else if (lowerTitle.includes("pinterest")) {
        return "/images/icons/platforms/icons8-pinterest-96.png";
    } else if (lowerTitle.includes("reddit")) {
        return "/images/icons/platforms/icons8-reddit-96.png";
    } else if (lowerTitle.includes("whatsapp")) {
        return "/images/icons/platforms/icons8-whatsapp-96.png";
    } else if (lowerTitle.includes("simply")) {
        return "/images/icons/platforms/icons8-simply-56.png";
    }
    return "/images/icons/platforms/icons8-link-90.png";
};

// Lifecycle
onMounted(() => {
    window.addEventListener("keydown", handleKeyDown);
});

onBeforeUnmount(() => {
    window.removeEventListener("keydown", handleKeyDown);
});

// Keyboard
const handleKeyDown = (event: KeyboardEvent) => {
    if (["ArrowDown", "ArrowUp", "Enter"].includes(event.key)) {
        event.preventDefault();
    }

    if (event.key === "ArrowDown") {
        selectedIndex.value = (selectedIndex.value + 1) % linkList.value.length;
    } else if (event.key === "ArrowUp") {
        selectedIndex.value = (selectedIndex.value - 1 + linkList.value.length) % linkList.value.length;
    } else if (event.key === "Enter" && selectedIndex.value >= 0) {
        const selectedLink = linkList.value[selectedIndex.value].link;
        window.open(selectedLink, "_blank");
    }
};

// Watchers
watch(isEditing, (newVal, oldVal) => {
    if (!newVal && oldVal) {
        // Transitioning from editing to not editing
        persistNewLinkIfNeeded();
        saveLinks(); // Emit updated links to the parent
    }
});

</script>


<template>
    <section class="modal-information-section">
        <div v-if="isEditing">
            <!-- Edit Mode -->
            <div v-for="(link, index) in linkList" :key="index"
                :class="['link-item', { dragged: draggedItemIndex === index, over: draggedItemIndex !== null && draggedItemIndex !== index }]"
                draggable="true" @dragstart="onDragStart($event, index)" @dragover="onDragOver"
                @drop="onDrop($event, index)" @dragend="onDragEnd">
                <i class="pi pi-fw pi-link link-icon"></i>
                <InputText type="text" v-model="link.title" placeholder="Title" class="input-field" />
                <InputText type="text" v-model="link.link" placeholder="Link" class="input-field" />
                <Button @click="removeLink(index)" icon="pi pi-times" severity="danger" text rounded
                    aria-label="Cancel" />
            </div>
            <div class="new-link">
                <i class="pi pi-fw pi-link link-icon"></i>
                <InputText type="text" v-model="newLink.title" placeholder="New Title" class="input-field" />
                <InputText type="text" v-model="newLink.link" placeholder="New Link" class="input-field" />
                <Button @click="addLink" :label="t('contactLinkView.button.add')" severity="info" text size="small" />
            </div>
        </div>
        <div v-else>
            <!-- View Mode -->
            <ul>
                <li v-for="(link, index) in linkList" :key="index" :class="{ 'selected-link': index === selectedIndex }"
                    class="link-item">
                    <img :src="getIconSrc(link.title)" alt="Meta Logo" class="meta-logo link-icon" />
                    <a :href="link.link" target="_blank" rel="noopener noreferrer" class="link-text">
                        {{ link.title }}
                    </a>
                </li>
            </ul>
        </div>
    </section>
</template>

<style scoped>
.modal-information-section {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.link-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.input-field {
    margin-right: 0.5rem;
    padding: 0.4rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex-grow: 1;
}

.delete-button,
.add-button,
.save-button {
    padding: 0.4rem 0.6rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-button {
    background-color: red;
}

.new-link {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.delete-button:hover {
    background-color: darkred;
}

.add-button:hover,
.save-button:hover {
    background-color: #0056b3;
}

.link-text {
    color: #007bff;
    text-decoration: none;
    font-size: 1.2rem;
}

.link-text:hover {
    text-decoration: underline;
}

.link-icon {
    height: 1.2rem;
    width: 1.2rem;
    font-size: 1.2rem;
    color: #555;
    margin-right: 1rem;
}

.selected-link {
    background-color: #f0f0f0;
    border-radius: 4px;
}

/* Drag over CSS */
.link-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: background-color 0.3s;
}

.link-item.dragged {
    background-color: var(--primary-color);
    /* Highlight dragged item */
}

.link-item.over {
    background-color: var(--blue-100);
    /* Highlight drop target */
}
</style>

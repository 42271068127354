import { createStore } from 'vuex';

// State
import authState from './state/auth/authState';
import userState from './state/user/userState';
import notificationsState from './state/notifications/notificationsState';
import subscriptionPlans from './state/subscriptionPlans';
import contactsState from './state/contacts/contactsState';
import communicationState from './state/communication/communicationState';
import marketingTimelineState from './state/marketing/marketingTimelineState';
import consultantState from './state/consultant/consultantState';

// Actions
import authActions from './actions/auth/authActions';
import userActions from './actions/user/userActions';
import notificationsActions from './actions/notifications/notificationsActions';
import aiActions from './actions/ai/aiActions';
import webSocketActions from './actions/webSocketActions';
import metaActions from './actions/marketing/meta/metaMarketingActions';
import fileActions from './actions/files/filesActions';
import filesProfileActions from './actions/files/filesProfileActions';
import filesContactsActions from './actions/files/filesContactsActions';
import contactsActions from './actions/contacts/contactsActions';
import calendarActions from './actions/calendar/calendarActions';
import userRelationsActions from './actions/user/userRelationsActions';
import marketingActions from './actions/marketing/marketingActions';
import marketingTimelineActions from './actions/marketing/marketingTimelineActions';
import paymentActions from './actions/payment/paymentActions';
import messageActions from './actions/communication/messageActions';
import messagesActions from './actions/communication/messagesActions';

// Mutations
import authMutations from './mutations/user/authMutations';
import userMutations from './mutations/user/userMutations';
import notificationsMutations from './mutations/notifications/notificationsMutations';
import generalMutations from './mutations/general/generalMutations';
import toastMutations from './mutations/toastMutations';
import webSocketMutations from './mutations/webSocketMutations';
import membershipMutations from './mutations/membership/membershipMutations';
import consultantMutations from './mutations/consultant/consultantMutations';
import facebookMutations from './mutations/marketing/meta/metaMarketingMutations';
import fileMutations from './mutations/files/filesMutations';
import calendarMutations from './mutations/calendar/calendarMutations';
import userRelationsMutations from './mutations/userRelationsMutations';
import marketingMutations from './mutations/marketing/marketingMutations';
import marketingTimelineMutations from './mutations/marketing/marketingTimelineMutations';
import paymentMutations from './mutations/paymentMutations';
import messageMutations from './mutations/communication/messageMutations';
import messagesMutations from './mutations/communication/messagesMutations';
import contactsMutations from './mutations/contacts/contactsMutations';

// Getters
import getters from './getters/getters';
import generalGetters from './getters/general/generalGetters';
import authGetters from './getters/auth/authGetters';
import userGetters from './getters/user/userGetters';
import userRelationsGetters from './getters/userRelations/userRelationsGetters';
import notificationsGetters from './getters/notifications/notificationsGetters';
import marketingGetters from './getters/marketing/marketingGetters';
import communicationGetters from './getters/communication/messagesGetters';
import fileGetters from './getters/files/fileGetters';
import marketingTimelineGetters from './getters/marketingTimelineGetters';
import paymentGetters from './getters/paymentGetters';
import contactsGetters from './getters/contacts/contactsGetters';

const store = createStore({
  state: {
    token: null,
    toast: null,
    ...authState,
    ...userState,
    ...notificationsState,
    ...subscriptionPlans,
    ...communicationState,
    membership: {
      selectedMembership: null,
    },
    userUpdate: {
      message: '',
      status: null,
    },
    files: {
      categorie_size_image: 0,
      categorie_size_documents: 0,
      categorie_size_videos: 0,
      files: [{}],
      filesSorted: [{}],
      folders: [{}],
      selectedFolder: '',
    },
    calendar: {
      marketingCalendar: {
        calendarDataArray: null,
      },
    },
    applyForPartnershipStatus: {
      status: null,
      message: '',
    },
    signupStatus: {
      status: null,
      message: '',
    },
    loginSucess: null,
    ...consultantState,
    ...marketingTimelineState,
    ...contactsState,
    selectedTimePeriod: null,
  },
  mutations: {
    ...authMutations,
    ...userMutations,
    ...notificationsMutations,
    ...generalMutations,
    ...toastMutations,
    ...webSocketMutations,
    ...membershipMutations,
    ...consultantMutations,
    ...facebookMutations,
    ...fileMutations,
    ...calendarMutations,
    ...userRelationsMutations,
    ...messageMutations,
    ...messagesMutations,
    ...marketingMutations,
    ...marketingTimelineMutations,
    ...paymentMutations,
    ...contactsMutations,
  },
  actions: {
    ...authActions,
    ...webSocketActions,
    ...userActions,
    ...notificationsActions,
    ...aiActions,
    ...metaActions,
    ...fileActions,
    ...filesProfileActions,
    ...filesContactsActions,
    ...calendarActions,
    ...userRelationsActions,
    ...messageActions,
    ...messagesActions,
    ...marketingActions,
    ...marketingTimelineActions,
    ...paymentActions,
    ...contactsActions,
  },
  getters: {
    ...getters,
    ...generalGetters,
    ...authGetters,
    ...userGetters,
    ...userRelationsGetters,
    ...notificationsGetters,
    ...communicationGetters,
    ...fileGetters,
    ...marketingTimelineGetters,
    ...paymentGetters,
    ...marketingGetters,
    ...contactsGetters,
  },
});

export default store;

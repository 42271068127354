export default {
  storeCalendar(state, calendarData) {
    state.calendar = calendarData;
  },
  patchCalendarEvent(state, response) {
    const eventIndex = state.calendar.marketingCalendar.calendarDataArray.findIndex(
      (event) => event.uuid === response.uuid
    );

    if (eventIndex !== -1) {
      state.calendar.marketingCalendar.calendarDataArray[eventIndex] = response;
    } else {
      console.error('Event not found in the calendarDataArray');
    }
  },
  setFullCalendarEventModalProps(state, modalProps) {
    state.fullCalendarEventModal = {
      ...state.fullCalendarEventModal,
      ...modalProps,
    };
  },
  storeMarketingCalendar(state, calendarData) {
    state.calendar.marketingCalendar = calendarData;
  },
  deleteCalendarEvent(state, response) {
    state.calendar.marketingCalendar.calendarDataArray = state.calendar.marketingCalendar.calendarDataArray.filter(
      (event) => {
        return event.uuid !== response.deletedEventUUID;
      }
    );
  },
};

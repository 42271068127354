<script setup>
import { computed, ref } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useLocaleMessages } from '@/utils/locales/useLocaleMessages';

// Components
import ImageModal from '@/components/overlay/ImageModal.vue';

// Load locales
useLocaleMessages({
  da: () => import('@/locales/da/landingPage.json'),
  en: () => import('@/locales/en/landingPage.json'),
});

const showContentCalendarGallery = ref(false);
const imageList = [
  "/images/landingPage/content-kalender-1.png",
  "/images/landingPage/content-kalender-2.png",
  "/images/landingPage/content-kalender-3.png",
  "/images/landingPage/content-kalender-4.png",
];
const openGallery = () => {
  showContentCalendarGallery.value = true;
};

const { layoutConfig } = useLayout();

const darkMode = computed(() => {
  return layoutConfig.colorScheme.value !== 'light';
});

</script>


<template>
  <!-- Features Title and Subtitle -->
  <div class="text-900 font-bold text-6xl mb-4 text-center">
    {{ $t('features-title') }}
  </div>
  <span class="text-700 block text-xl mb-8 text-center line-height-3">
    {{ $t('features-subtitle') }}
  </span>

  <!-- Features Section -->
  <div class="flex flex-column lg:flex-row align-items-center justify-content-between mt-8 gap-8">
    <!-- Tracking Feature -->
    <div class="flex flex-column align-items-center">
      <img :src="`/images/landingPage/kontrolpanel-graph.png`" alt="Tracking"
        class="images border-round surface-border shadow-2 animation-duration-500 scalein origin-top" />
      <span class="block text-900 text-lg font-semibold mt-4">
        {{ $t('tracking-title') }}
      </span>
      <div class="mt-4">
        <span class="text-700 block text-center line-height-3">
          {{ $t('tracking-subtitle') }}
        </span>
      </div>
    </div>

    <!-- Content Calendar Feature -->
    <div class="flex flex-column align-items-center content-calendar">
      <div class="image-container" @click="openGallery">
        <img :src="`/images/landingPage/content-kalender-1.png`" alt="Content Calendar"
          class="images border-round surface-border shadow-2 animation-duration-500 scalein origin-top" />
        <div class="image-overlay">
          <span class="overlay-text">{{ $t('see-more-text') }}</span>
        </div>
      </div>
      <ImageModal v-if="showContentCalendarGallery" :showModal="showContentCalendarGallery" :images="imageList"
        @close="showContentCalendarGallery = false" />
      <span class="block text-900 text-lg font-semibold mt-4">
        {{ $t('content-calendar-title') }}
      </span>
      <div class="mt-4">
        <span class="text-700 block text-center line-height-3">
          {{ $t('content-calendar-subtitle') }}
        </span>
      </div>
    </div>

    <!-- Status Messages Feature -->
    <div class="flex flex-column align-items-center">
      <img :src="`/images/landingPage/status-messages.png`" alt="Status Messages"
        class="images border-round surface-border shadow-2 animation-duration-500 scalein origin-top" />
      <span class="block text-900 text-lg font-semibold mt-4">
        {{ $t('status-messages-title') }}
      </span>
      <div class="mt-4">
        <span class="text-700 block text-center line-height-3">
          {{ $t('status-messages-subtitle') }}
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.description {
  height: 10rem;
}

.images {
  height: 20rem;
  width: 25rem;
}

.image-container {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .image-overlay {
  opacity: 1;
}

.overlay-text {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>